import { ThemeProvider } from "@material-ui/core/styles";
import * as Sentry from "@sentry/react";
import { StrictMode } from "react";
import ReactDOM from "react-dom";
import TagManager, { TagManagerArgs } from "react-gtm-module";
import "./index.css";
import { Navigation } from "./Navigation";
import { theme } from "./theme/theme";

/* eslint-disable no-console */

const tagManagerArgs: TagManagerArgs = {
  gtmId: "GTM-TCRFM3K",
};

TagManager.initialize(tagManagerArgs);

if (process.env.REACT_APP_SENTRY_DSN?.length) {
  try {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
    });
  } catch (e) {
    // Adblock could be blocking sentry
    console.warn("In case of technical errors, please disable Adblock so they can reach us.");
  }
}

console.log(
  "%cSomething not right? Send us feedback at%c testovac@vacuumlabs.com 📩",
  'color: #e72076; font-family: Times, "Times New Roman", Georgia, serif; font-size: 2.5rem; font-weight: 400;',
  'color: #212d3b; font-family: Times, "Times New Roman", Georgia, serif; font-size: 2.5rem; font-weight: 600'
);

ReactDOM.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <Navigation />
    </ThemeProvider>
  </StrictMode>,
  document.getElementById("root")
);
