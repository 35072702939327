export const buttonMixin = (backgroundColor: string, color: string) => ({
  backgroundColor,
  color,
  "&:hover": {
    backgroundColor,
    opacity: 0.8,
  },
});

export const inlineExceptionalHeading = (isInline: boolean) =>
  isInline ? { lineHeight: "inherit" } : { display: "block" };

export const nonAppearingInput = () => ({
  position: "absolute",
  "-moz-appearance": "none",
  "-webkit-appearance": "none",
  appearance: "none",
  outline: "none",
});
