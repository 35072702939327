import { colors } from "../consts/themeConsts";
import { buttonMixin } from "./mixins";

export const overrides = {
  MuiButton: {
    root: {
      padding: ".75rem 1rem",
      borderRadius: ".25rem",
      lineHeight: 1.5,
      fontSize: ".875rem",
      fontWeight: 600,
      transition: "none",
      textTransform: "none",
    },
    containedPrimary: {
      ...buttonMixin(colors.hotpink, colors.white),
    },
    containedSecondary: {
      ...buttonMixin(colors.black, colors.white),
    },
    contained: {
      boxShadow: "none",
    },
    outlinedSecondary: {
      border: `1px solid ${colors.textGrey}`,
      padding: ".75rem 1rem",
    },
  },
  MuiChip: {
    root: {
      marginBottom: "0.5rem",
      fontWeight: 700,
      fontSize: "0.875rem",
      lineHeight: "1.5rem",
      padding: ".75rem 1rem",
      borderRadius: "0.25rem",
      width: "100%",
      height: "auto",
      fontFamily: "inherit",
    },
    sizeSmall: {
      width: "auto",
      padding: "0",
    },
  },
  MuiContainer: {
    maxWidthMd: {
      maxWidth: 736,
    },
  },
  MuiAppBar: {
    root: {
      padding: ".5rem",
    },
  },
  MuiSelect: {
    outlined: {
      "&:focus": {
        backgroundColor: colors.lightGrey,
      },
    },
  },
  MuiOutlinedInput: {
    root: {
      "&$focused $notchedOutline": {
        borderColor: colors.black,
        color: colors.black,
      },
    },
  },
  MuiFormLabel: {
    root: {
      "&$focused": {
        borderColor: colors.black,
        color: colors.black,
      },
    },
  },
  MuiListItemIcon: {
    root: {
      color: "inherit",
    },
  },
  MuiPaper: {
    rounded: {
      borderRadius: "0.5rem",
    },
  },
  MuiLink: {
    underlineHover: {
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  MuiTableSortLabel: {
    active: {
      color: `${colors.white} !important`,
    },
    icon: {
      color: `${colors.hotpink} !important`,
    },
  },
  //React-admin overrides
  RaFilterForm: {
    form: {
      width: "100%",
    },
  },
  RaMenuItemLink: {
    active: {
      borderLeft: "3px solid",
      borderColor: colors.hotpink,
    },
    root: {
      color: colors.black,
    },
  },
  RaLink: {
    link: {
      "&:hover": {
        textDecoration: "underline",
      },
    },
  },
  RaLayout: {
    appFrame: {
      marginTop: "0!important",
    },
  },
  RaSingleFieldList: {
    root: {
      marginTop: "0",
      marginBottom: "0",
    },
    link: {
      marginRight: "5px",
      "& *": {
        color: colors.hotpink,
      },
    },
  },
  RaSelectArrayInput: {
    chip: {
      width: "auto",
      padding: "0",
    },
  },
} as const;
