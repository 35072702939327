import { colors } from "../consts/themeConsts";

const heroHeading = {
  fontWeight: 600,
  textAlign: "center",
  letterSpacing: "-0.015em",
  fontFamily: "Eina-semibold",
  verticalAlign: "text-bottom",
} as const;

const exceptionalFont = {
  fontFamily: "Exceptional",
  textAlign: "center",
  color: colors.hotpink,
  fontWeight: "normal",
  marginBottom: "0",
  verticalAlign: "inherit",
} as const;

export const typography = {
  fontFamily: ["Eina", "san-serif"].join(","),
  allVariants: {
    color: colors.textDark,
  },
  //hero-title
  h1: {
    ...heroHeading,
    fontSize: "3.125rem",
    lineHeight: "4.3125rem",
  },
  //hero-title small
  h2: {
    ...heroHeading,
    fontSize: "2.5rem",
    lineHeight: "3.4375rem",
  },
  h3: {
    ...heroHeading,
    fontSize: "2.1875rem",
    fontWeight: "bold",
    textAlign: "center",
    color: colors.hotpink,
    marginBottom: "1.25rem",
  },
  exceptionalBig: {
    ...exceptionalFont,
    fontSize: "5.5rem",
    lineHeight: "7.125rem",
  },
  exceptionalSmall: {
    ...exceptionalFont,
    fontSize: "4rem",
    lineHeight: "5.25rem",
  },
  body1: {
    fontSize: "1rem",
    lineHeight: "1.8125rem",
  },
  body2: {
    fontSize: ".875rem",
    lineHeight: "1rem",
  },
  subtitle1: {
    fontSize: "1.5rem",
    lineHeight: "2.5rem",
  },
  subtitle2: {
    fontSize: "1.125rem",
    lineHeight: "1.5rem",
  },
  caption: {
    fontSize: "1.25rem",
  },
} as const;
