export const colors = {
  textDark: "#212d3b",
  textRed: "#e72076",
  textGrey: "#79848C",
  black: "#20323F",
  white: "#ffffff",
  defaultBlack: "#000000",
  hotpink: "#e62076",
  darkHotpink: "#c12d2d",
  lightHotpink: "#FAD2E4",
  brightRed: "#ffb3b3",
  blue: "#619bb7",
  yellow: "#dbb809",
  green: "#5ba06d",
  lightGrey: "#f4f5f5",
  grey: "#d8d8d8",
  darkGrey: "#888888",
  secondaryTextColor: "#a1a1a1",
  statusRed: "#dc3545",
  statusOrange: "#ffc107",
  statusGreen: "#28a745",
  statusBlue: "#210BEB",
  avocado: "#909a03",
  graphite: "#616161",
  mango: "#e08300",
  alertRed: "#fdeded",
  alertOrange: "#fff4e5",
} as const;

export const dataGridStyles = {
  headerCell: {
    backgroundColor: colors.black,
    color: colors.white,
    paddingTop: "1em",
    paddingBottom: "1em",
    "&:last-child": {
      borderTopRightRadius: "0",
    },
    "&:first-child": {
      borderTopLeftRadius: "0",
    },
  },
};
