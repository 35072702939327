import { createTheme } from "@material-ui/core/styles";
import "./assets/index.css";
import { boxShadow } from "./customProps";
import { buttonMixin, inlineExceptionalHeading, nonAppearingInput } from "./mixins";
import { overrides } from "./overrides";
import { palette } from "./palette";
import { typography } from "./typography";

export const theme = createTheme({
  palette,
  mixins: {
    buttonMixin,
    inlineExceptionalHeading,
    nonAppearingInput,
  },
  boxShadow,
  typography,
  overrides,
});
