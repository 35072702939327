import { colors } from "../consts/themeConsts";

export const palette = {
  common: {
    white: colors.white,
    black: colors.defaultBlack,
  },
  primary: {
    main: colors.hotpink,
    light: colors.lightHotpink,
    dark: colors.darkHotpink,
  },
  secondary: {
    main: colors.black,
    light: colors.lightGrey,
  },
  success: {
    main: colors.green,
  },
  text: {
    primary: colors.textDark,
    secondary: colors.darkGrey,
    tertiary: colors.black,
  },
  background: {
    default: colors.lightGrey,
    paper: colors.white,
  },
} as const;
