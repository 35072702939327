import loadable from "@loadable/component";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

const AdminDashboard = loadable(() => import("./pages/AdminDashboard"));
const LoginPage = loadable(() => import("./pages/LoginPage"));
const NotFoundPage = loadable(() => import("./pages/NotFoundPage"));

export const Navigation = () => {
  // Because React Admin uses it's own HashRouter, we have to exclude it from our own router,
  // otherwise the 2 would conflict.
  if (window.location.pathname.startsWith("/v2.0/admin")) {
    return <AdminDashboard />;
  }

  return (
    <BrowserRouter basename="/v2.0" forceRefresh>
      <Switch>
        <Redirect path="/" exact to="/admin" />
        <Route path="/login" component={LoginPage} />
        <Route path="*" exact component={NotFoundPage} />
      </Switch>
    </BrowserRouter>
  );
};
